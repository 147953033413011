import { useEffect, useState } from 'react';
import { Row, Column } from 'carbon-components-react';
import { AllUrls, LOCAL_URLS, MAIN_URLS, PROD_URLS, STAGE_URLS, FEATURE_URLS, FrontEndUrls } from '../../utils/urls';
import { useUserContext } from '../../context/UserContext';
import { getDevInfo } from '../../utils/api';
import styles from './DevInfo.module.css';

function DevInfo() {
  const [apiBranch, setAPIBranch] = useState<string>('');
  const { isTester } = useUserContext();

  const getEnv = (url: AllUrls) => {
    if (PROD_URLS.includes(url)){
      return 'production';
    }
    if (MAIN_URLS.includes(url)){
      return 'main';
    }
    if (STAGE_URLS.includes(url)){
      return 'staging';
    }
    if (FEATURE_URLS.includes(url)){
      return 'feature';
    }
    if (LOCAL_URLS.includes(url)){
      return 'local';
    }
    return '';
  };

  const frontEnd = getEnv(window.location.origin as AllUrls);

  if (frontEnd === 'production' || !process.env.REACT_APP_IDEAS_API_URL) return null;
  if (!isTester) {
    return (
      <div className={styles.userInfo}>
        <h5>This is a dev environment, please go to <a href={FrontEndUrls.prod1}>ideas.ibm.com</a></h5>
      </div>
    );
  }

  const api = getEnv(process.env.REACT_APP_IDEAS_API_URL as AllUrls);

  useEffect(() => {
    const checkUser = async () => {
      const { data } = await getDevInfo(api);
      setAPIBranch(data);
    };
    checkUser();
  }, []);

  const branch = process.env.REACT_APP_BRANCH || '';

  return (
    <div className={styles.devInfo}>
      <Row>
        <Column><h5>Frontend: {frontEnd}</h5></Column>
        <Column><h5>Frontend Branch: {branch}</h5></Column>
        <Column><h5>API: {api}</h5></Column>
        <Column><h5>API Branch: {apiBranch}</h5></Column>
      </Row>
    </div>
  );
}

export default DevInfo;
