import { Grid, Row, Column } from 'carbon-components-react';
import { Outlet } from 'react-router-dom';
import PageHeader from '../PageHeader/PageHeader';
import Sidebar from '../Sidebar/Sidebar';
import styles from './PageLayout.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import DevInfo from '../DevInfo/DevInfo';

function PageLayout() {
  return (
    <Grid>
      <Row>
        <NotificationBanner />
      </Row>
      <Row>
        <DevInfo />
      </Row>
      <Row>
        <Column>
          <PageHeader />
        </Column>
      </Row>
      <Row>
        <Column>
          <div className={styles.container}>
            <Sidebar />
            <main className={styles.main}>
              <Outlet />
            </main>
          </div>
        </Column>
      </Row>
    </Grid>
  );
}

export default PageLayout;
