/* eslint-disable import/prefer-default-export */

export enum FrontEndUrls {
  prod1 = 'https://ideas.ibm.com',
  prod2 = 'https://ideas-portal2-production.bbxnpsih12h.us-south.codeengine.appdomain.cloud',
  main = 'https://ideas-portal2-main.7swist4l2qz.us-south.codeengine.appdomain.cloud',
  stage = 'https://ideas-portal2-staging.7swist4l2qz.us-south.codeengine.appdomain.cloud',
  feature = 'https://ideas-portal-feature.7swist4l2qz.us-south.codeengine.appdomain.cloud',
  local = 'http://localhost:3000'
}

export enum APIUrls {
  prod = 'https://api.ideas.ibm.com',
  main = 'https://ideas-api.7swist4l2qz.us-south.codeengine.appdomain.cloud',
  stage = 'https://ideas-api-staging.7swist4l2qz.us-south.codeengine.appdomain.cloud',
  feature = 'https://ideas-api-feature.7swist4l2qz.us-south.codeengine.appdomain.cloud',
  local = 'http://localhost:5603'
}

export type AllUrls = FrontEndUrls | APIUrls;

export const ALLOWED_URLS = [
  APIUrls.prod,
  APIUrls.main,
  APIUrls.stage,
  APIUrls.feature,
];

export const PROD_URLS = [
  FrontEndUrls.prod1,
  FrontEndUrls.prod2,
  APIUrls.prod,
];

export const MAIN_URLS = [
  FrontEndUrls.main,
  APIUrls.main,
];

export const STAGE_URLS = [
  FrontEndUrls.stage,
  APIUrls.stage,
];

export const FEATURE_URLS = [
  FrontEndUrls.feature,
  APIUrls.feature,
];

export const LOCAL_URLS = [
  FrontEndUrls.local,
  APIUrls.local,
];
