import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import { User } from '../models/user';
import { getUser, setAuthErrorCallback } from '../utils/api';
import NotificationBanner from '../components/NotificationBanner/NotificationBanner';
import { ALLOWED_URLS } from '../utils/urls';

interface UserContextInterface {
  user: User | null;
  isIBMEmployee: boolean;
  isProductScoutWWLeader: boolean;
  isProductScoutGeoLeader: boolean;
  isMarketScout: boolean;
  isTester: boolean;
  setGlobalNotification: Function;
}

const validateURL = (url: string | undefined) => {
  for (const index in ALLOWED_URLS) {
    if (url && url.startsWith(`${ALLOWED_URLS[index]}/`)) {
      return url;
    }
  }
  throw new Error('Environment variable: REACT_APP_IDEAS_API_URL not allowed');
};

const UserContext = createContext<UserContextInterface | null>(null);

export function useUserContext() {
  return useContext(UserContext)!;
}

interface UserProviderProps {
  children: React.ReactNode
}

export function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<User | null>(null);

  if (!process.env.REACT_APP_IDEAS_API_URL) {
    throw new Error('Missing environment variable: REACT_APP_IDEAS_API_URL');
  }

  const redirectToLogin = () => {
    const url = `${process.env.REACT_APP_IDEAS_API_URL}/ibmid/authorize?frontend_redirect_url=${window.location.href}`;
    window.location.href = validateURL(url);
  };

  useEffect(() => {
    setAuthErrorCallback(() => {
      setUser(null);
      redirectToLogin();
    });
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      const { data } = await getUser();
      setUser(data);
    };
    checkUser();
  }, []);

  const isIBMEmployee = useMemo(
    () => (user ? user.access_roles.includes('INTERNAL') : false),
    [user]
  );

  const isProductScoutWWLeader = useMemo(
    () => (user ? user.access_roles.includes('PRODUCT_SCOUT_LEADER') : false),
    [user]
  );

  const isProductScoutGeoLeader = useMemo(
    () => (user ? user.access_roles.includes('PRODUCT_SCOUT_GEO_LEADER') : false),
    [user]
  );

  const isMarketScout = useMemo(
    () => (user ? user.access_roles.includes('MARKET_SCOUT') : false),
    [user]
  );

  const isTester = useMemo(
    () => (user ? user.access_roles.includes('TEST_PRODUCTS') : false),
    [user]
  );

  const setGlobalNotification = (value: boolean) => {
    if (user) user.settings.global_email_notification = value;
  };

  const userContext: UserContextInterface = useMemo(() => ({
    user,
    isIBMEmployee,
    isProductScoutWWLeader,
    isProductScoutGeoLeader,
    isMarketScout,
    isTester,
    setGlobalNotification,
  }), [
    user,
    isIBMEmployee,
    isProductScoutWWLeader,
    isProductScoutGeoLeader,
    isMarketScout,
    isTester,
    setGlobalNotification,
  ]);

  return (
    <UserContext.Provider value={userContext}>
      {user ? (
        children
      ) : (
        <>
          <NotificationBanner />
          <LoadingSpinner text="Logging in..." />
        </>
      )}
    </UserContext.Provider>
  );
}

export default UserContext;
