/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Launch, Time, Unlink, Renew, ViewOff, Save, Edit } from '@carbon/icons-react';
import { Button, InlineNotification, TextInput, TooltipDefinition, TooltipIcon } from 'carbon-components-react';
import { getIdea, syncIdea, updateIdea } from '../../utils/api';
import useAPI from '../../hooks/useAPI';
import { sanitizeHTML } from '../../utils/sanitize';
import { formatDate } from '../../utils/dates';
import { lighten } from '../../utils/colors';
import { WORKFLOW_TOOLTIP_TEXTS } from '../../utils/workflowState';
import { Visibility, getVisibilityText } from '../../utils/ideaVisibility';
import { getIdeaIssueTrackerURL } from '../../utils/issueTracker';
import { commaSeparate } from '../../utils/format';
import Tag from '../../components/Tag/Tag';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { IssueTrackerInclusionState } from '../../utils/issueTrackerInclusionStates';
import { Idea, IdeaUpdate } from '../../models/idea';
import NotFound from '../NotFound/NotFound';
import Votes from '../../components/Votes/Votes';
import { useToastContext } from '../../context/ToastContext';
import { useUserContext } from '../../context/UserContext';
import { useCommentContext } from '../../context/CommentContext';
import Comments from '../../components/Comments/Comments';
import CommentButton from '../../components/CommentButton/CommentButton';
import AdminResponse from '../../components/AdminResponse/AdminResponse';
import ButtonSpinner from '../../components/ButtonSpinner/ButtonSpinner';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import styles from './SingleIdea.module.css';
import SubscribeButton from '../../components/SubscribeButton/SubscribeButton';

function SingleIdea() {
  const { isIBMEmployee } = useUserContext();
  const { ideaId } = useParams();
  const ideaAPI = useAPI(getIdea);
  const ideaAPISync = useAPI(syncIdea);
  const { commentCount, setCommentCount } = useCommentContext();
  const { addToast } = useToastContext();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [isIdeaCreator, setIsIdeaCreator] = useState(false);
  const [ideaName, setIdeaName] = useState('');
  const [description, setDescription] = useState('');

  const [sync, setSync] = useState(false);
  let syncing = false;

  let idea: Idea | null = null;

  const resetIdea = () => {
    idea = null;
    ideaAPI.reset();
    ideaAPISync.reset();
    setSync(false);
  };

  useEffect(() => {
    resetIdea();
    ideaAPI.execute(ideaId);
  }, [ideaId]);

  const syncIdeaClick = () => {
    resetIdea();
    setSync(true);
    syncing = true;
    ideaAPISync.execute(ideaId);
  };

  if (sync && ideaAPISync.data) {
    idea = ideaAPISync.data;
    syncing = false;
  } else if (!sync && ideaAPI.data) {
    idea = ideaAPI.data;
  }

  const onEdit = () => {
    // When edit it clicked, set all the textboxes and dropdowns to current idea data
    if (idea) {
      setDescription(sanitizeHTML(idea.description_full));
      setIdeaName(idea.name);
    }

    setIsEditing(true);
  };
  const onSave = async () => {
    if (!idea) return;
    setIsSaving(true);

    const updateData: IdeaUpdate = {
      name: ideaName,
      description,
    };

    try {
      await updateIdea(idea.id, updateData);
      setIsEditing(false);
      resetIdea();
      ideaAPI.execute(ideaId);
    } catch {
      addToast({
        kind: 'error',
        title: 'Failed to update idea',
        message: 'Something went wrong, please try again later'
      });
    }
    addToast({
      kind: 'success',
      title: 'The idea has been updated',
    });
    setIsSaving(false);
  };

  useEffect(() => {
    if (idea) {
      setCommentCount(idea.comment_count);
      setIsIdeaCreator(idea.is_requester_creator);
    }
  }, [idea]);

  if (ideaAPI.error || ideaAPISync.error) {
    return <NotFound />;
  }

  if (sync && !idea) {
    return (
      <div className={styles.idea}>
        <div className={styles.content}>
          <LoadingSpinner text="Updating idea..." />
        </div>
        <div className={styles.sidebar} />
      </div>
    );
  }

  if (!idea) {
    return <LoadingSpinner text="Loading idea..." />;
  }

  const workflowTooltipText = WORKFLOW_TOOLTIP_TEXTS[idea.workflow_state];
  const issueTrackerURL = getIdeaIssueTrackerURL(idea);

  let categoryElements: JSX.Element[] = [];

  if (idea && idea.product_categories && idea.product_categories.length !== 0) {
    categoryElements = idea.product_categories.slice(0, 3).map((category) => (
      <Link key={category.id} to={`/products/${idea!.product_id}/categories/${category.id}`}>{category.name}</Link>
    ));

    if (idea.product_categories.length > 3) {
      categoryElements.push(
        <TooltipDefinition tooltipText={idea.product_categories.slice(3).map((category) => category.name).join(', ')}>...</TooltipDefinition>
      );
    }
  }
  const visibilityTooltipText = getVisibilityText(idea.visibility, idea.requester_email, idea.is_requester_creator);

  let mergedMessage;
  if (idea.duplicate_of){
    const duplicateReference = idea.duplicate_of.reference;
    mergedMessage = (
      <>
        <p>This idea has been merged into another idea.
          To comment or vote on this idea, please visit <Link to={`/ideas/${duplicateReference}`}>{duplicateReference}</Link> instead.
        </p>
        {(idea.duplicate_of.visibility !== Visibility.PUBLIC) && (
          <p>Note: The merged idea is visible only to the creator and IBM</p>
        )}
      </>
    );
  }

  return (
    <div className={styles.idea}>
      <div className={styles.content}>
        {idea.duplicate_of && (
        <InlineNotification
          className={styles.mergedMessage}
          kind="info"
          title="Merged idea"
          lowContrast
          hideCloseButton
        >{mergedMessage}
        </InlineNotification>
        )}
        <div className={styles.header}>
          <div>
            <div className={styles.product}>
              Idea for <Link to={`/products/${idea.product_id}`}>{idea.product}</Link>{' '}
              {categoryElements.length !== 0 && (
                <span>
                  (Category: {commaSeparate(categoryElements)})
                </span>
              )}
            </div>
          </div>
          <div className={styles.singleIdeaHeader}>
            {visibilityTooltipText && (
              <TooltipIcon className={styles.visibilityIcon} tooltipText={visibilityTooltipText} align="start"><ViewOff /></TooltipIcon>
            )}
            {isEditing ? (
              <TextInput
                className={styles.ideaName}
                id="idea-name"
                name="name"
                labelText=""
                required
                autoComplete="off"
                value={ideaName}
                onChange={(e) => setIdeaName(e.target.value)}
                disabled={isSaving}
              />
            ) : (
              <h2 className={styles.ideaName}>{idea.name}</h2>
            )}
          </div>
          <div className={styles.meta}>
            <Votes idea={idea} />
            <CommentButton reference={idea.reference} commentCount={commentCount} />
            <span><Time /> Created on {formatDate(idea.created_at)}</span>
            {idea.workflow_state && (
              <div className={styles.workflowContainer}>
                <div
                  className={styles.workflow}
                  style={{
                    borderColor: idea.workflow_color,
                    backgroundColor: lighten(idea.workflow_color, 0.7)
                  }}
                >
                  {workflowTooltipText ? (
                    <TooltipDefinition direction="bottom" align="start" tooltipText={workflowTooltipText}>
                      {idea.workflow_state}
                    </TooltipDefinition>
                  ) : (
                    idea.workflow_state
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <section className={styles.description}>
          {isEditing ? (
            <RichTextEditor data={description} onChange={setDescription} disabled={isSaving} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(idea.description_full) }} />
          )}
        </section>
        {idea.impact_statement && (
          <section>
            <h3>Impact</h3>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(idea.impact_statement || '') }} />
          </section>
        )}
        {idea.status_synopsis && (
          <section>
            <h3>Current Status</h3>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(idea.status_synopsis || '') }} />
          </section>
        )}
        {idea.admin_response && (
          <section>
            <AdminResponse idea={idea} />
          </section>
        )}
        <Comments idea={idea} />
      </div>
      <div className={styles.sidebar}>
        <section className={styles.sidebarLinks}>
          {isIdeaCreator && ((isEditing || isSaving) ? (
            <div className={styles.actionButtons}>
              <Button size="sm" kind="secondary" onClick={() => setIsEditing(false)} disabled={isSaving}>Cancel</Button>
              <ButtonSpinner icon={Save} onClick={onSave} isLoading={isSaving} loadingText="Saving...">Save</ButtonSpinner>
            </div>
          ) : (
            <Button disabled={isEditing} className={styles.sidebarLink} onClick={onEdit} size="sm" renderIcon={Edit} kind="tertiary">
              Edit Idea
            </Button>
          ))}
          <SubscribeButton idea={idea} />
          {(idea.url && idea.visibility !== Visibility.NOT_VISIBLE) ? (
            <Button className={styles.sidebarLink} href={idea.url} target="_blank" rel="noopener noreferrer" size="sm" renderIcon={Launch} kind="tertiary">
              See in the Aha! portal
            </Button>
          ) : (
            <div>
              <Unlink /> This idea is not in an Aha! portal
            </div>
          )}
          {isIBMEmployee && (
            <Button disabled={syncing} className={styles.sidebarLink} onClick={() => syncIdeaClick()} size="sm" renderIcon={Renew} kind="tertiary">
              Get Latest data from Aha!
            </Button>
          )}
          {isIBMEmployee && issueTrackerURL && (
            <Button className={styles.sidebarLink} as={Link} to={issueTrackerURL} size="sm" renderIcon={Launch} kind="tertiary">
              See in the Issue Tracker
            </Button>
          )}
        </section>
        {isIBMEmployee && (
          <>
            <section>
              <div className={styles.field}>
                <div className={styles.label}>Created by</div>
                <div className={styles.value}>{idea.requester_email}</div>
              </div>
              {idea.assignee_email && (
              <div className={styles.field}>
                <div className={styles.label}>Assigned to</div>
                <div className={styles.value}>{idea.assignee_email}</div>
              </div>
              )}
              <div className={styles.field}>
                <div className={styles.label}>Aha! Reference</div>
                <div className={styles.value}>{idea.reference}</div>
              </div>
            </section>
            <section className={styles.issueTrackerFields}>
              <div className={styles.field}>
                <div className={styles.label}>Issue Tracker Inclusion</div>
                <div className={styles.value}>
                  {idea.issue_tracker_top_product_slug ? idea.issue_tracker_inclusion : 'Not in Product Scout scope'}
                </div>
              </div>
              {idea.issue_tracker_inclusion !== IssueTrackerInclusionState.UNSET && (
              <div className={styles.field}>
                <div className={styles.label}>Candidate Date</div>
                <div className={styles.value}>
                  {(idea.issue_tracker_candidate_date) ? (formatDate(idea.issue_tracker_candidate_date)) : ('Date not available')}
                </div>
              </div>
              )}
              {idea.issue_tracker_approval_date && (
              <div className={styles.field}>
                <div className={styles.label}>Approved Date</div>
                <div className={styles.value}>
                  {formatDate(idea.issue_tracker_approval_date)}
                </div>
              </div>
              )}
              {idea.issue_tracker_inclusion && idea.issue_tracker_inclusion !== IssueTrackerInclusionState.UNSET && (
              <div className={styles.field}>
                <div className={styles.label}>Outlook</div>
                <div className={styles.value}>
                  {(idea.outlook || 'Outlook TBD')}
                </div>
              </div>
              )}
              {idea.classification_themes && idea.classification_themes.length > 0 && (
              <div className={styles.field}>
                <div className={styles.label}>Classification Theme</div>
                <div className={styles.value}>
                  {idea.classification_themes.join(', ')}
                </div>
              </div>
              )}
              {idea.issue_tracker_inclusion_requester && (
              <div className={styles.field}>
                <div className={styles.label}>Issue Tracker Requester</div>
                <div className={styles.value}>
                  {idea.issue_tracker_inclusion_requester}
                </div>
              </div>
              )}
              <div className={styles.field}>
                {idea.ibm_wide_managed_tags?.sort().map((tag) => (
                  <div className={styles.managedTagContainer}>
                    <Tag name={tag} colour="#DDD" />
                  </div>
                ))}
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default SingleIdea;
