import axios, { AxiosRequestConfig } from 'axios';
import { StoredFile } from '../models/file';
import { Group } from '../models/group';
import { GroupMember, GroupMemberRequest, GroupMemberRequestLogs } from '../models/groupMember';
import { HiddenIdea } from '../models/hiddenIdeas';
import { Idea, IdeaComment, IdeaUpdate, NewIdea } from '../models/idea';
import { IdeaProduct, Product, ProductCategory, IdeaProductCategory, FollowProduct } from '../models/product';
import { IssueTrackerItem, IssueTrackerListingFilters, IssueTrackerParent, IssueTrackerOutlookSummary, IssueTrackerInclusionSummary } from '../features/issueTracker/models/issueTracker';
import { NewTag, Tag } from '../models/tag';
import { User } from '../models/user';
import { ALL_SET_INCLUSION_STATES, ISSUE_TRACKER_INCLUDED_STATES, IssueTrackerInclusionState } from './issueTrackerInclusionStates';
import { IBMCompany } from '../models/company';
import { startOfMonth, startOfNextMonth } from './dates';
import { REJECTED_BY_PRODUCT_TEAM_STATES, RESOLVED_IDEAS_STATES } from './workflowState';

const axiosConfig: AxiosRequestConfig<any> = {
  baseURL: process.env.REACT_APP_IDEAS_API_URL,
};

if (process.env.REACT_APP_API_KEY) {
  axiosConfig.headers = {
    AccessToken: process.env.REACT_APP_API_KEY || '',
    'Requester-email': process.env.REACT_APP_REQUESTER_EMAIL || ''
  };
} else {
  axiosConfig.withCredentials = true;
}

const api = axios.create(axiosConfig);

export function setAuthErrorCallback(cb: () => void) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        cb();
      }
      return Promise.reject(error);
    }
  );
}

function createParamsArray(key: string, values: string[]) {
  const params = new URLSearchParams();

  values.forEach((value) => {
    params.append(key, value);
  });

  return params;
}

function convertParams(params: { [key: string]: string | string[] | number }) {
  const newParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const values = params[key];
    if (Array.isArray(values)) {
      values.forEach((value) => {
        newParams.append(key, value);
      });
    } else {
      newParams.append(key, values.toString());
    }
  });
  return newParams;
}

export async function getUser() {
  return api.get<User>('/users/me');
}

export async function getDevInfo(env: string) {
  return api.get<string>(`/users/devinfo/${env}`);
}

export function getIdeas(params: { [key: string]: string | string[] | number }, abortController: AbortController) {
  const newParams = convertParams(params);
  return api.get<Idea[]>('/api/v1/ideas', {
    signal: abortController.signal,
    params: newParams
  });
}

export function getIdeasCSVURL(params: { [key: string]: string | string[] | number }) {
  const newParams = convertParams(params);
  return `${process.env.REACT_APP_IDEAS_API_URL}/api/v1/ideas/csv?${newParams}`;
}

export function getIdea(ideaId: string) {
  return api.get<Idea>(`/api/v1/ideas/${ideaId}`);
}

export function getIdeaComments(ideaId: string, params: { [key: string]: string | string[] | number }) {
  const newParams = convertParams(params);
  return api.get<IdeaComment[]>(`/api/v1/ideas/${ideaId}/idea_comments`, {
    params: newParams
  });
}

export function postIdeaComment(ideaId: string, params: { [key: string]: string }) {
  return api.post(`/api/v1/ideas/${ideaId}/idea_comments`, { ...params });
}

export function syncIdea(ideaId: string) {
  return api.get<Idea>(`/api/v1/ideas/${ideaId}/sync`);
}

export function getIdeasProducts(params: { [key: string]: string | number }, abortController: AbortController) {
  const newParams = convertParams(params);
  return api.get<IdeaProduct[]>('/api/v1/ideas/products', {
    signal: abortController.signal,
    params: newParams
  });
}

export function getIdeaCategories(params: { [key: string]: string | number }, abortController: AbortController) {
  const newParams = convertParams(params);
  return api.get<IdeaProductCategory[]>('/api/v1/ideas/categories', {
    signal: abortController.signal,
    params: newParams
  });
}

export function getIdeasStatuses(params: { [key: string]: string | number }, abortController: AbortController) {
  const newParams = convertParams(params);
  return api.get<IdeaProduct[]>('/api/v1/ideas/workflow_statuses', {
    signal: abortController.signal,
    params: newParams
  });
}

export function getProducts(query: string, action: string, abortController: AbortController) {
  return api.get<Product[]>('/api/v1/products', {
    signal: abortController.signal,
    params: { query, action }
  });
}

export function getProductCategories(productId: string) {
  return api.get<ProductCategory[]>(`/api/v1/products/${productId}/categories`);
}

export function addIdea(idea: NewIdea) {
  return api.post('/api/v1/ideas', { ...idea, idea_source: 'Unified Ideas Portal' });
}

export function updateIdea(ideaId: string, ideaUpdate: IdeaUpdate) {
  return api.patch(`/api/v1/ideas/${ideaId}`, ideaUpdate);
}

export function voteForIdea(ideaId: string) {
  return api.put(`/api/v1/ideas/${ideaId}/votes`);
}

export function unvoteForIdea(ideaId: string) {
  return api.delete(`/api/v1/ideas/${ideaId}/votes`);
}

export function subscribeToIdea(ideaId: string) {
  return api.put(`/api/v1/ideas/${ideaId}/subscription`);
}

export function unSubscribeToIdea(ideaId: string) {
  return api.delete(`/api/v1/ideas/${ideaId}/subscription`);
}

export function uploadFile(file: File) {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return api.post<StoredFile>('/api/v1/files', formData);
}

export function removeFile(fileId: string) {
  return api.delete(`/api/v1/files/${fileId}`);
}

export function getGroups() {
  return api.get<Group[]>('/api/v1/groups');
}

export function createGroup(groupName: string) {
  return api.post<Group>('/api/v1/groups', {
    name: groupName
  });
}

export function getGroup(groupId: string) {
  return api.get<Group>(`/api/v1/groups/${groupId}`);
}

export function updateGroup(groupId: string, groupName: string) {
  return api.put(`/api/v1/groups/${groupId}`, {
    name: groupName
  });
}

export function deleteGroup(groupId: string) {
  return api.delete<Group>(`/api/v1/groups/${groupId}`);
}

export function addIdeasToGroup(groupId: string, ideaIds: string[]) {
  const params = new URLSearchParams();

  ideaIds.forEach((ideaId) => {
    params.append('idea_ids', ideaId);
  });

  return api.post<Group>(
    `/api/v1/groups/${groupId}/ideas`,
    {},
    { params }
  );
}

export function removeIdeasFromGroup(groupId: string, ideaIds: string[]) {
  const params = createParamsArray('idea_ids', ideaIds);

  return api.delete<Group>(
    `/api/v1/groups/${groupId}/ideas`,
    { params }
  );
}

export function getHiddenIdeas(groupId: string) {
  return api.get<HiddenIdea>(`/api/v1/groups/${groupId}/hidden_ideas`);
}

export function getGroupTags(groupId: string) {
  return api.get<Tag[]>(`/api/v1/groups/${groupId}/tags`);
}

export function addGroupTag(groupId: string, tag: NewTag) {
  return api.post<Tag>(`/api/v1/groups/${groupId}/tags`, tag);
}

export function updateGroupTag(groupId: string, tagId: string, tag: NewTag) {
  return api.put(`/api/v1/groups/${groupId}/tags/${tagId}`, tag);
}

export function removeGroupTag(groupId: string, tagId: string) {
  return api.delete(`/api/v1/groups/${groupId}/tags/${tagId}`);
}

export function addNewTagToIdeas(groupId: string, ideaIds: string[], tag: NewTag) {
  const params = createParamsArray('idea_ids', ideaIds);

  return api.post<Tag>(
    `/api/v1/groups/${groupId}/ideas/tag`,
    tag,
    { params },
  );
}

export function addTagToIdeas(groupId: string, ideaIds: string[], tagId: string) {
  const params = createParamsArray('idea_ids', ideaIds);

  return api.post<Tag>(
    `/api/v1/groups/${groupId}/tags/${tagId}/ideas`,
    {},
    { params },
  );
}

export function removeTagFromIdeas(groupId: string, ideaIds: string[], tagId: string) {
  const params = createParamsArray('idea_ids', ideaIds);

  return api.delete(
    `/api/v1/groups/${groupId}/tags/${tagId}/ideas`,
    { params },
  );
}

export function getGroupMembers(groupId: string) {
  return api.get<GroupMember[]>(`/api/v1/groups/${groupId}/users`);
}

export function addGroupMember(groupId: string, newGroupMember: GroupMember) {
  return api.post(`/api/v1/groups/${groupId}/users`, newGroupMember);
}

export function removeGroupMember(groupId: string, email: string) {
  return api.delete(`/api/v1/groups/${groupId}/users`, {
    params: {
      portal_user_email: email
    }
  });
}

export function updateGroupMember(groupId: string, newGroupMember: GroupMember) {
  return api.put(`/api/v1/groups/${groupId}/users`, newGroupMember);
}

export function requestGroupAccess(groupId: string) {
  return api.post(`/api/v1/groups/${groupId}/user-requests`);
}

export function getGroupMemberRequests(groupId: string) {
  return api.get<GroupMemberRequest[]>(`/api/v1/groups/${groupId}/user-requests`);
}

export function rejectGroupMemberRequest(groupId: string, email: string) {
  return api.delete(`/api/v1/groups/${groupId}/user-requests`, {
    params: {
      portal_user_email: email
    }
  });
}

export function getGroupMemberRequestLogs(groupId: string) {
  return api.get<GroupMemberRequestLogs[]>(`/api/v1/groups/${groupId}/user-logs`);
}

export function getCustomFieldValues(customFieldKey: string) {
  return api.get<string[]>(`/api/v1/ideas/custom_field_values/${customFieldKey}`);
}

export function addIBMWideManagedTagToIdeas(ideaIds: string[], tag: string) {
  const params = createParamsArray('idea_ids', ideaIds);
  params.append('managed_tag', tag);

  return api.put(
    '/api/v1/ideas/managed_tags',
    {},
    { params }
  );
}

export function removeIBMWideManagedTagFromIdeas(ideaIds: string[], tag: string) {
  const params = createParamsArray('idea_ids', ideaIds);
  params.append('managed_tag', tag);

  return api.delete(
    '/api/v1/ideas/managed_tags',
    { params }
  );
}

export function getProduct(productId: string) {
  return api.get<Product>(`/api/v1/products/${productId}`);
}

export function getProductCategory(productId: string, categoryId: string) {
  return api.get<Product>(`/api/v1/products/${productId}/categories/${categoryId}`);
}

export function getFollowedProducts() {
  return api.get<FollowProduct[]>('/api/v1/followed_products');
}

export function followProduct(productId: string) {
  return api.put(`/api/v1/followed_products/${productId}`);
}

export function unfollowProduct(productId: string) {
  return api.delete(`/api/v1/followed_products/${productId}`);
}

export function followProductCategory(productId: string, categoryId: string) {
  return api.put(`/api/v1/followed_products/${productId}/${categoryId}`);
}

export function unfollowProductCategory(productId: string, categoryId: string) {
  return api.delete(`/api/v1/followed_products/${productId}/${categoryId}`);
}

export function getRecommendedProducts() {
  return api.get<IdeaProduct[]>('/api/v1/recommended_products');
}

export function getIssueTrackerMenu() {
  return api.get<IssueTrackerParent[]>('/api/v1/issue_tracker/menu');
}

export function getIssueTrackerOutlookSummary(productId: string, managedTags: string[], big10: string | null, approvedFrom: string | null, approvedTo: string | null) {
  let inclusionStatuses = ISSUE_TRACKER_INCLUDED_STATES;

  if (big10 === 'true') {
    inclusionStatuses = [IssueTrackerInclusionState.THE_BIG_10];
  } else if (big10 === 'false') {
    inclusionStatuses = [IssueTrackerInclusionState.APPROVED_BY_WW];
  }

  const params = createParamsArray('inclusion_status', inclusionStatuses);
  params.append('include_resolved', 'true');

  for (const managedTag of managedTags) {
    params.append('ibm_wide_managed_tags', managedTag);
  }

  if (approvedFrom) {
    params.append('from_date', startOfMonth(approvedFrom));
  }

  if (approvedTo) {
    params.append('until_date', startOfNextMonth(approvedTo));
  }

  let url = '/api/v1/issue_tracker/outlook_summary';

  if (productId !== 'ibm') {
    url = `/api/v1/issue_tracker/products/${productId}/outlook_summary`;
  }

  return api.get<IssueTrackerOutlookSummary[]>(url, { params });
}

export function getIssueTrackerInclusionSummary(productId: string, managedTags: string[]) {
  const params = createParamsArray('include_resolved', ['false']);

  for (const managedTag of managedTags) {
    params.append('ibm_wide_managed_tags', managedTag);
  }

  let url = '/api/v1/issue_tracker/inclusion_summary';

  if (productId !== 'ibm') {
    url = `/api/v1/issue_tracker/products/${productId}/inclusion_summary`;
  }

  return api.get<IssueTrackerInclusionSummary[]>(url, { params });
}

export function getIssueTrackerItems(productId: string, inclusionStatuses: IssueTrackerInclusionState[], filters: IssueTrackerListingFilters, abortController: AbortController) {
  let filteredInclusionStatuses = inclusionStatuses;

  if (inclusionStatuses.includes(IssueTrackerInclusionState.THE_BIG_10)) {
    if (filters.big10 === 'true') {
      // Removing 'Approved by WW'
      filteredInclusionStatuses = [IssueTrackerInclusionState.THE_BIG_10];
    } else if (filters.big10 === 'false') {
      // Removing 'The Big 10'
      filteredInclusionStatuses = [IssueTrackerInclusionState.APPROVED_BY_WW];
    }
  }

  const params = createParamsArray('inclusion_status', filteredInclusionStatuses);
  filters.classificationThemes.forEach((classificationTheme) => {
    params.append('classification_themes', classificationTheme);
  });

  filters.outlooks.forEach((outlook) => {
    if (outlook === 'Outlook TBD') {
      params.append('outlook', '');
    } else {
      params.append('outlook', outlook);
    }
  });

  filters.ibmWideManagedTags.forEach((managedTag) => {
    params.append('ibm_wide_managed_tags', managedTag);
  });

  if (filters.status === 'Rejected by Product Team') {
    REJECTED_BY_PRODUCT_TEAM_STATES.forEach((workflowState) => {
      params.append('workflow_statuses', workflowState);
    });
    params.append('include_resolved', 'true');
  } else if (filters.status === 'Resolved Ideas') {
    RESOLVED_IDEAS_STATES.forEach((workflowState) => {
      params.append('workflow_statuses', workflowState);
    });
    params.append('include_resolved', 'true');
  }

  filters.productIds.forEach((productID) => {
    params.append('product_ids', productID);
  });

  filters.categoryIds.forEach((categoryID) => {
    params.append('category_ids', categoryID);
  });

  if (filters.approvedFrom) {
    params.append('from_date', startOfMonth(filters.approvedFrom));
  }

  if (filters.approvedTo) {
    params.append('until_date', startOfNextMonth(filters.approvedTo));
  }

  return api.get<IssueTrackerItem[]>(`/api/v1/issue_tracker/products/${productId}/items`, { params, signal: abortController.signal });
}

export function getIssueTrackerItemsProducts(productId: string, abortController: AbortController) {
  const params = createParamsArray('inclusion_status', ALL_SET_INCLUSION_STATES);
  params.append('include_resolved', 'true');

  return api.get<IdeaProduct[]>(`/api/v1/issue_tracker/products/${productId}/items/products`, { params, signal: abortController.signal });
}

export function setIssueTrackerInclusion(ideaId: string, inclusionStatus: string) {
  return api.put('/api/v1/ideas/field_issue_tracker_inclusion', {}, {
    params: {
      issue_tracker_inclusion: inclusionStatus,
      idea_ids: ideaId
    }
  });
}

export function removeIdeaFromCluster(clusterUuid: string, ideaId: string) {
  return api.delete(`/api/v1/ideas/relate/${clusterUuid}/${ideaId}`);
}

export function addIdeasToCluster(ideaId1: string, ideaId2: string) {
  return api.put(`/api/v1/ideas/relate?idea_ids=${ideaId1}&idea_ids=${ideaId2}`);
}

export function getCompanies(query: string, abortController: AbortController) {
  return api.get<IBMCompany[]>('/api/v1/companies', {
    signal: abortController.signal,
    params: { query }
  });
}

export function getCompanyGuess() {
  return api.get<IBMCompany | null>('/users/me/company_guess');
}

export function registerCompanyInAha(company: IBMCompany) {
  return api.post<IBMCompany>('/api/v1/companies', {
    ibm_company_id: company.ibm_company_id,
    ibm_company_id_type: company.ibm_company_id_type
  });
}

export function updateUserSettings(settingName: string, settingValue: boolean) {
  const url = `/users/me/settings?${settingName}=${settingValue}`;
  return api.post(url);
}
